import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { As, Button, ButtonProps } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';

type DeviceAdaptiveActionButtonProps = {
  onClick: () => void;
  text: string;
  isDisabled?: boolean;
  desktopProps?: ButtonProps;
  mobileProps?: ButtonProps;
  as?: As;
};

const DeviceAdaptiveActionButton = ({
  onClick,
  text,
  isDisabled,
  desktopProps,
  mobileProps,
  as,
}: DeviceAdaptiveActionButtonProps) => {
  const { isDesktop } = useScreenInfos();

  return isDesktop ? (
    <Button
      as={as}
      leftIcon={<MdAdd />}
      variant='solid'
      colorScheme='primary'
      size='sm'
      onClick={() => onClick()}
      my={1}
      mx={1}
      zIndex={1}
      isDisabled={!!isDisabled}
      {...desktopProps}
    >
      {text}
    </Button>
  ) : (
    <Button
      as={as}
      leftIcon={<MdAdd />}
      variant='extendedFAB'
      size='lg'
      onClick={() => onClick()}
      {...mobileProps}
    >
      {text}
    </Button>
  );
};

export default DeviceAdaptiveActionButton;
